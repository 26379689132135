import Blazy from 'blazy';

export default {
    data() {
        return {
            bLazy: null,
        }
    },
    methods: {
        Blazy() {
            this.bLazy = new Blazy();

            setTimeout(this.bLazy.revalidate, 600);
        },
    },
    created() {
        this.Blazy();
    },
}
