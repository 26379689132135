export default {
    start: function () {
        // As soon as the loading is finished and the old page is faded out, let's fade the new page
        Promise
            .all([this.newContainerLoading, this.fadeOut()])
            .then(this.fadeIn.bind(this));
    },

    fadeOut: function () {
        return new Promise((resolve, reject) => {
            document.querySelector('.barba-bg').classList.add('barba-bg--visible');
            document.body.classList.remove('body--loaded');
            window.setTimeout(function () {
                resolve();
            }, 50);
        });
    },

    fadeIn: function () {
        (this.oldContainer).style.display = 'none';

        window.scrollTo(0, 0);

        document.querySelector('.barba-bg').classList.remove('barba-bg--visible');

        setTimeout(function(){
            document.body.classList.add('body--loaded');
        }, 250);

        this.done();
    }
}
