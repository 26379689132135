export default {
    methods: {
        ViewportHeight(){
            window.addEventListener('orientationchange', function(){
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
            setTimeout( function(){
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            }, 500);
        },
    },
    created() {
        this.ViewportHeight();
    },
}