export default {
    nl: {
        messages: {
            _default: (field) => `Dit veld is niet correct ingevuld.`,
            after: (field, [target, inclusion]) => `Dit veld moet groter ${inclusion ? 'of gelijk aan ' : ''} ${target}.`,
            alpha_dash: (field) => `Dit veld mag alleen alfanumerieke karakters, strepen en onderstrepingstekenen bevatten.`,
            alpha_num: (field) => `Dit veld mag alleen alfanumerieke karakters bevatten.`,
            alpha_spaces: (field) => `Dit veld mag alleen alfanumerieke karakters en spaties bevatten.`,
            alpha: (field) => `Dit veld mag alleen alfabetische karakters bevatten.`,
            before: (field, [target, inclusion]) => `De waarde van dit veld moet kleiner ${inclusion ? 'of gelijk aan' : ''} ${target} zijn.`,
            between: (field, [min, max]) => `De waarde van dit veld moet tussen ${min} en ${max} zijn.`,
            confirmed: (field) => `Dit bevestigingsveld komt niet overeen.`,
            credit_card: (field) => `Dit veld is ongeldig.`,
            date_between: (field, [min, max]) => `De datum moet tussen ${min} en ${max} zijn.`,
            date_format: (field, [format]) => `Dit veld moet het volgende formaat hebben: ${format}.`,
            decimal: (field, [decimals = '*'] = []) => `Dit veld mag alleen numerieke, en${!decimals || decimals === '*' ? ' ' : decimals}decimale nummers bevatten.`,
            digits: (field, [length]) => `Dit veld moet ${length} nummers bevatten.`,
            dimensions: (field, [width, height]) => `De dimensies voor dit veld moet ${width} pixels breed en ${height} pixels hoog zijn.`,
            email: (field) => `Dit veld moet een geldig e-mailadres bevatten.`,
            ext: (field) => `Dit veld moet een correct bestand bevatten.`,
            image: (field) => `Dit veld moet een afbeelding bevatten.`,
            included: (field) => `Dit veld moet een geldige waarde bevatten.`,
            integer: (field) => `Dit veld moet een nummer zijn.`,
            ip: (field) => `Dit veld moet een veilig ip adres zijn.`,
            length: (field, [length, max]) => {
                if (max) {
                    return `Dit veld moet minimaal ${length} karakters en maximaal ${max} karakters bevatten.`;
                }

                return `Dit veld moet minimaal ${length} karakters lang zijn.`;
            },
            max: (field, [length]) => `Dit veld mag niet meer karakters bevatten dan ${length}.`,
            max_value: (field, [max]) => `Dit veld mag een maximale waarde hebben van ${max}.`,
            mimes: (field) => `Dit veld moet Dit juiste type bestand bevatten.`,
            min: (field, [length]) => `Dit veld moet minimaal ${length} karakters zijn.`,
            min_value: (field, [min]) => `Dit veld moet een minimale waarde hebben van ${min}.`,
            excluded: (field) => `Dit veld moet een geldige waarde bevatten`,
            numeric: (field) => `Dit veld mag alleen numerieke karakters bevatten.`,
            regex: (field) => `Dit veld is niet correct ingevoerd.`,
            required: (field) => `Dit veld is verplicht.`,
            size: (field, [size]) => `De bestandsgrootte van dit veld mag niet groter zijn dan ${formatFileSize(size)}.`,
            url: (field) => `Dit veld moet een valide URL zijn.`
        }
    }
};
