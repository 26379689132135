// Basics
import Vue from 'vue';

// import * as Sentry from "@sentry/browser";
// import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations } from '@sentry/tracing';
// Sentry.init({
//     dsn: "https://bfeece19def24f02aed6fec371082291@o445840.ingest.sentry.io/5423203",
//     integrations: [
//         new VueIntegration({
//             Vue,
//             tracing: true
//         }),
//         new Integrations.BrowserTracing()
//     ],
//     tracesSampleRate: 1
// });

import VeeValidate from 'vee-validate';
import VeeDictionary from './settings/vee-dictionary';
import VeeExtensions from './settings/vee-extensions';
import VeePasswordStrength from './settings/vee-password-strength';
import Barba from 'barba.js';
import store from './store/store.js';
import {VueMasonryPlugin} from 'vue-masonry';

// Mixins
import mixinBlazy from './mixins/blazy';
import mixinScroll from './mixins/scroll';
import mixinViewportheight from './mixins/viewportheight';
import mixinDatafilled from './mixins/datafilled';

// Settings
import barba_basic_fadetransition from './settings/barba_basic_fadetransition';

// Global
let Global;

// Vue plugins
Vue.use(VeeValidate, {
    locale: document.documentElement.lang,
    events: 'blur|submit|change',
    classes: true,
    invalidateFalse: true,
    classNames: {
        invalid: 'input--invalid',
        valid: 'input--valid'
    },
    dictionary: VeeDictionary,
});
Vue.use(VueMasonryPlugin);

// Na inladen DOM inhoud
document.addEventListener("DOMContentLoaded", function () {
    document.querySelector('body').classList.add('body--loaded');

    Barba.Pjax.getTransition = function () {
        return Barba.BaseTransition.extend(barba_basic_fadetransition);
    };
    Barba.Pjax.cacheEnabled = false;
    Barba.Pjax.start();
});

// Na transitie door Barba
Barba.Dispatcher.on("transitionCompleted", function () {
    // Vue root
    Global = new Vue({
        el: document.getElementById("app"),
        store,
        mixins: [
            mixinBlazy,
            mixinScroll,
            mixinViewportheight,
            mixinDatafilled,
        ],
        components: {
            'header-component': () => import('./components/header/header-component'),
            'form-component': () => import('./components/form-component'),
            'participation-form': () => import('./components/participation-form'),
            'become-member-form': () => import('./components/become-member-form'),
            'entrepreneurs-form': () => import('./components/entrepreneurs-form'),
            'new-members-form': () => import('./components/new-members-form'),
            'newsletter-form': () => import('./components/newsletter-form'),
            'default-overview': () => import('./components/default-overview'),
            'masonry-overview': () => import('./components/masonry-overview'),
            'masonry-view': () => import('./components/masonry-view'),
            'accordion': () => import('./components/accordion'),
            'timeline': () => import('./components/timeline'),
            'team': () => import('./components/team'),
            'plyr-video': () => import('./components/plyr-video'),
            'flickity-slider': () => import('./components/flickity-slider'),
            'related-modules-webkey': () => import('./components/related-modules/related-modules-webkey'),
            'local-overview': () => import('./components/local/overview'),
            'sites-overview': () => import('./components/sites-overview'),
            'checkout-cart': () => import('./components/checkout-cart'),
            'checkout': () => import('./components/checkout'),
            'product-card': () => import('./components/webshop/product-card'),
            'product-detail': () => import('./components/webshop/product-detail'),
            'product-slider': () => import('./components/webshop/product-slider'),
        },
    })
});

// Na inladen pagina door Barba
Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container, newPageRawHTML) => {

    let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
        match = regexp.exec(newPageRawHTML);
    if(!match || !match[1]) {} // do nothing
    else {
        document.body.setAttribute('class', match[1]);
    }

    /**
     * Toevoeging voor het correct tracken in analytics
     *
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
     */

    if (Barba.HistoryManager.history.length <= 1) {
        return;
    }

    // send statics by Google Analytics(analytics.js) or Google Tag Manager
    if (typeof ga === 'function' && typeof ga.getAll === 'function') {
        var trackers = ga.getAll();
        trackers.forEach(function (tracker) {
            ga(tracker.get('name') + '.set', 'page', location.pathname);
            ga(tracker.get('name') + '.send', 'pageview');
        });
    }
});

// Na verandering link door Barba
Barba.Dispatcher.on('initStateChange', function () {
    if(Global) {
        Global.$destroy();
    }
});

// polyfills

if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}

if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function(search, rawPos) {
            var pos = rawPos > 0 ? rawPos|0 : 0;
            return this.substring(pos, pos + search.length) === search;
        }
    });
}

if (typeof Object.assign != 'function') {
    Object.assign = function(target, varArgs) { // .length of function is 2
        'use strict';
        if (target == null) { // TypeError if undefined or null
            throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
            var nextSource = arguments[index];

            if (nextSource != null) { // Skip over if undefined or null
                for (var nextKey in nextSource) {
                    // Avoid bugs when hasOwnProperty is shadowed
                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                    }
                }
            }
        }
        return to;
    };
}
