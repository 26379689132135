import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        cart: {
            quantity: document.getElementById('cartQuantity') ? document.getElementById('cartQuantity').dataset.quantity : 0,
            item: null,
        }
    },
    getters: {
        isMobile() {
            return store.state.isMobile;
        }
    },
    mutations: {
        SET_CART_ITEM(state, item) {
            state.cart.item = item;
        },
        SET_CART_QUANTITY(state, quantity) {
            state.cart.quantity = quantity;
        }
    },
    actions: {
        addToCart({commit}, params) {
            return new Promise((resolver, rejecter) => {
                axios.post('sites/webshop/api/cart', params)
                    .then(response => {
                        let item = response.data.data.items.find(item => item.product.productID == params.product);
                        item.added = params.quantity;

                        commit('SET_CART_ITEM', item);
                        commit('SET_CART_QUANTITY', response.data.data.quantity);

                        resolver(response);
                    }).catch((err) => {
                        rejecter(err);
                    });
            });
        },
        setCartQuantity({commit}, quantity) {
            commit('SET_CART_QUANTITY', quantity);
        },
        setCartItem({commit}, item) {
            commit('SET_CART_ITEM', item);
        }
    },
});

export default store
