import MoveTo from 'moveto';

export default {
    data() {
        let headerHeight = 0;
        if (document.querySelector('header')) {
            headerHeight = document.querySelector('header').clientHeight;
        }

        return {
            MoveToInstance: new MoveTo({
                tolerance: 120,
                duration: 800,
            }),
        }
    },
    methods: {
        SmoothScroll() {
            const that = this;

            document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                if (anchor.getAttribute('href').length > 1) {
                    anchor.addEventListener('click', function (e) {
                        e.preventDefault();

                        if(document.querySelector(this.getAttribute('href')) != null) {
                            let anker = document.querySelector(this.getAttribute('href'));

                            that.MoveToInstance.move(anker);
                        } else {
                            let anchorLink = this.getAttribute('href');
                            anchorLink = anchorLink.replace("#", "");
                            let anker = document.querySelector('a[name="'+ anchorLink +'"]');

                            that.MoveToInstance.move(anker);
                        }
                    });
                }
            });
        },
        ScrollTo(target){
            let elm = document.querySelector(target);
            this.MoveToInstance.move(elm);
        },
    },
    mounted() {
        this.SmoothScroll();
    },
}
